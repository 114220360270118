<template>
  <div>
    <label for="status">
      Situação
    </label>
    <multiselect
      placeholder="Selecionar..."
      v-model="status"
      @input="handleStatus"
      class="with-border status"
      :options="statusOptions"
      :showLabels="false"
      :internalSearch="false"
      :searchable="false"
      :disabled="disabledStatus()"
    >
      <template slot="caret">
        <div class="chevron">
          <ChevronDown />
        </div>
      </template>
      <template slot="option" slot-scope="props">
        <span class="option" v-if="props.option == 'SCHEDULED'">
          <div class="situation scheduled"/>
          Sem situação
        </span>
        <div class="option" v-else-if="props.option == 'CONFIRMED'">
          <div class="situation confirmed"/>
          Confirmado
        </div>
        <div class="option" v-else-if="props.option == 'ATTEND'">
          <div class="situation attend"/>
          Compareceu
        </div>
        <div class="option" v-else-if="props.option == 'WAITING'">
          <div class="situation waiting"/>
          Aguardando atendimento
        </div>
        <div class="option" v-else-if="props.option == 'MISSED'">
          <div class="situation missed"/>
          Faltou
        </div>
        <div class="option" v-else-if="props.option == 'CANCELLED'">
          <div class="situation cancelled"/>
          Cancelado
        </div>
        <div class="option" v-else-if="props.option == 'HAPPENING'">
          <div class="situation happening"/>
          Em atendimento médico
        </div>
        <div class="option" v-else-if="props.option == 'EXAMINATING'">
          <div class="situation examinating"/>
          Em exames
        </div>
        <div class="option" v-else-if="props.option == 'DILATING'">
          <div class="situation dilating"/>
          Dilatando
        </div>
        <div class="option" v-else-if="props.option == 'FINISHED'">
          <div class="situation finished"/>
          Finalizado
        </div>
        <div class="option" v-else-if="props.option == 'IN_PRE_CONSULTATION'">
          <div class="situation in_pre_consultation"/>
          Em pré-consulta
        </div>
        <div class="option" v-else-if="props.option == 'PRE_FINISHED'">
          <div class="situation pre_finished"/>
          Pré-consulta finalizada
        </div>
      </template>

      <template slot="singleLabel" slot-scope="{ option }">
        <span class="option" v-if="option == 'SCHEDULED'">
          <div class="situation scheduled"/>
          Sem situação
        </span>
        <div class="option" v-else-if="option == 'CONFIRMED'">
          <div class="situation confirmed"/>
          Confirmado
        </div>
        <div class="option" v-else-if="option == 'CONFIRMED_BY_PATIENT'">
          <div class="situation confirmed"/>
          Confirmado pelo paciente
        </div>
        <div class="option" v-else-if="option == 'ATTEND'">
          <div class="situation attend"/>
          Compareceu
        </div>
        <div class="option" v-else-if="option == 'WAITING'">
          <div class="situation waiting"/>
          Aguardando atendimento
        </div>
        <div class="option" v-else-if="option == 'MISSED'">
          <div class="situation missed"/>
          Faltou
        </div>
        <div class="option" v-else-if="option == 'CANCELLED'">
          <div class="situation cancelled"/>
          Cancelado
        </div>
        <div class="option" v-else-if="option == 'CANCELLED_BY_PATIENT'">
          <div class="situation cancelled"/>
          Cancelado pelo paciente
        </div>
        <div class="option" v-else-if="option == 'HAPPENING'">
          <div class="situation happening"/>
          Em atendimento médico
        </div>
        <div class="option" v-else-if="option == 'EXAMINATING'">
          <div class="situation examinating"/>
          Em exames
        </div>
        <div class="option" v-else-if="option == 'DILATING'">
          <div class="situation dilating"/>
          Dilatando
        </div>
        <div class="option" v-else-if="option == 'FINISHED'">
          <div class="situation finished"/>
          Finalizado
        </div>
        <div class="option" v-else-if="option == 'IN_PRE_CONSULTATION'">
          <div class="situation in_pre_consultation"/>
          Em pré-consulta
        </div>
        <div class="option" v-else-if="option == 'PRE_FINISHED'">
          <div class="situation pre_finished"/>
          Pré-consulta finalizada
        </div>
      </template>

      <template slot="noOptions">
        Nenhuma opção
      </template>

      <template slot="noResult">
        Nenhum resultado
      </template>
    </multiselect>
    <div v-if="validated && !value" class="custom-invalid-feedback">Campo obrigatório</div>
    <div v-if="statusTime" class="last-update">Atualizado em {{ parseTimestamp(statusTime) }}</div>
  </div>
</template>
<script>
import ChevronDown from '@/assets/icons/chevron-down.svg'
import { parseTimestamp } from '@/utils/dateHelper'

export default {
  props: {
    value: String,
    statusTime: String,
    validated: Boolean,
    validatedStatus: Boolean,
  },
  components: { ChevronDown },
  mounted() {
    if (this.value) {
      this.status = this.value
    }
  },
  data() {
    return {
      status: null,
      statusOptions: [
        'SCHEDULED',
        'CONFIRMED',
        'ATTEND',
        'WAITING',
        'MISSED',
        'CANCELLED',
        'HAPPENING',
        'EXAMINATING',
        'DILATING',
        'FINISHED',
        'PRE_FINISHED',
        'IN_PRE_CONSULTATION',
      ],
    }
  },
  methods: {
    handleStatus(status) {
      this.$emit('input', status)
      this.$emit('select', status)
    },
    parseTimestamp,
    disabledStatus() {
      if(this.validatedStatus)
        return this.status === 'PRE_FINISHED' ||
        this.status === 'FINISHED' ||
        this.status === 'EXAMINATING' ||
        this.status === 'HAPPENING' ||
        this.status === 'IN_PRE_CONSULTATION' ||
        this.status === 'CANCELLED_BY_PATIENT' ||
        this.status === 'CANCELLED'
    }
  },
  watch: {
    value(status) {
      this.status = status
    }
  }
}
</script>
<style lang="scss" scoped>
.status {
  width: 350px !important;

  @media (max-width: 1200px) {
    width: auto !important;
  }
}
.last-update {
  font-size: 13px;
  color: var(--type-placeholder);
}
.option {
  display: flex;
  align-items: center;
  gap: 5px
}
.situation {
  width: 18px;
  height: 18px;
  border: 2px solid var(--neutral-500);
  border-radius: 50%;

  &.scheduled {
    background: #FFFFFF;
    border: 3px solid #A4B1DF;
  }
  &.confirmed {
    background: #046340;
    border: 3px solid #F2FFE6;
  }
  &.confirmed_by_patient {
    background: #046340;
    border: 3px solid #F2FFE6;
  }
  &.attend {
    background: #0B30B2;
    border: 3px solid #E6F5FF;
  }
  &.missed {
    background: #F63220;
    border: 3px solid #FFD6D2;
  }
  &.cancelled {
    background: #525C7A;
    border: 3px solid #E9EBF2;
  }
  &.cancelled_by_patient {
    background: #525C7A;
    border: 3px solid #E9EBF2;
  }
  &.finished {
    background: #304388;
    border: 3px solid #F8FDFF;
  }
  &.waiting {
    background: #FFCA00;
    border: 3px solid #FFF6D5;
  }
  &.happening {
    background: #FF6B00;
    border: 3px solid #FFF3E1;
  }
  &.examinating {
    background: #D94F91;
    border: 3px solid #FFE3F1;
  }
  &.dilating {
    background: #673270;
    border: 3px solid #FBF0FF;
  }
  &.pre_finished {
    background: #613F26;
    border: 3px solid #FFEFE4;
  }
  &.in_pre_consultation{
    background: #23acb1;
    border: 3px solid #FFEFE4;
  }
}
</style>
